/**
 * Provides integration logic for Upstreamworks Click-to-Chat feature
 *
 * @module cartridge/js/upstreamworks
 */
'use strict';
const progress = require('org/progress');
const util = require('org/util');

// Get hidden input values for site prefs, urls
const upstreamworksEnabled = $('#upstreamworks-config').find('#upstreamworksEnabled').val() === 'true';
const upstreamworksAgentPollURL = $('#upstreamworks-config').find('#upstreamworksAgentPollURL').val();
const upstreamworksSkills = $('#upstreamworks-config').find('#upstreamworksSkills').val();
const upstreamworksRefresh = $('#upstreamworks-config').find('#upstreamworksAgentPollInterval').val();
const upstreamworksFormURL = $('#upstreamworks-config').find('#upstreamworksFormURL').val();
const upstreamworksBrand = $('#upstreamworks-config').find('#upstreamworksBrand').val();
const upstreamworksAvailablityThreshold = $('#upstreamworks-config').find('#upstreamworksAvailablityThreshold').val() || 5;
const upstreamworksDebug = $('#upstreamworks-config').find('#upstreamworksDebug').val() === 'true';

// Service response known values
const skillAddressProperty = 'skillAddress';
const skillStatusProperty = 'Status';
const skillCapacityProperty = 'CapacityPercentage';
const skillStatusOpen = 'open';

// Controller parameters that can define hidden input values in intake form
const upstreamworksFormHiddenParams = ['brand', 'origin', 'initializationsource'];

// Selectors
const chatDialogSelector = '.upstreamworks-chat-dialog';
const chatDialogCloseBtnSelector = '.upstreamworks-chat-dialog-close-btn';
const chatDialogExpandBtnSelector = '.upstreamworks-chat-dialog-expand-btn';
const clickToChatButtonSelector = '#upstreamworksChatButtonFooter, #upstreamworksChatButtonFooterContactUs'; // add more selectors if nec, comma-separated.
const chatIframeId = 'live-chat-window';

var upstreamworks = {

    agentsAvailable: false,
    chatSessionActive: false,
    chatDialogVisible: false,

    initClickToChat: function () {
        if (!upstreamworksEnabled) {
            return false;
        }

        // custom window events Upstreamworks chat can trigger
        $(window).on('message', function(e) {
            if (upstreamworksDebug) {
                window.console.log('-----MESSAGE EVENT-----');
                window.console.log(e);
            }
            if (e.originalEvent.data === 'uwfEnableButton') {
                upstreamworks.onChatEnable(e.originalEvent);
            } else if (e.originalEvent.data === 'uwfChatReady') {
                upstreamworks.onChatReady(e.originalEvent);
            } else if (e.originalEvent.data === 'uwfShowChatForActiveSession') {
                upstreamworks.onChatShown(e.originalEvent);
            } else if (e.originalEvent.data === 'uwfSaveChatEnvironment') {
                upstreamworks.onChatRestore(e.originalEvent);
            } else if (e.originalEvent.data === 'uwfButtonClick') {
                upstreamworks.onChatCollapsed(e.originalEvent);
            } else if (e.originalEvent.data === 'uwfResetChatEnvironment') {
                upstreamworks.onChatEnded(e.originalEvent);
            } else if (e.originalEvent.data === 'uwfChatFrameUrlChange') {
                upstreamworks.onChatReload(e.originalEvent);
            }
        });

        if ($('.user-info.international').length) {
            $(clickToChatButtonSelector).addClass('international');
        }

        // if Upstreamworks is enabled and at least one click-to-chat buttons exist in the page...
        if ($(clickToChatButtonSelector).length) {

            // hide liveperson button(s) and show upstreamworks button(s)
            $('#lpMCButtonFooter').hide();
            $(clickToChatButtonSelector).show();
            if (upstreamworksDebug) window.console.log('Upstreamworks click to chat button(s) shown');

            // enable buttons if at an agent for least one skill is available for chat
            this.getAgentsAvailable(upstreamworksSkills);

            // check for agent availability every 30 seconds, wether they were previously available or not
            setInterval(function() {
                if (!upstreamworks.chatSessionActive) {
                    upstreamworks.getAgentsAvailable(upstreamworksSkills);
                }
            }, upstreamworksRefresh * 1000);

            // set up event handlers
            $(document).on('click', clickToChatButtonSelector, function(e) {
                if ($(this).is('[disabled]')) {
                    e.preventDefault();
                    return;
                }
                if (upstreamworksDebug) {
                    window.console.log('Upstreamworks click to chat button clicked');
                    window.console.log(this);
                }
                upstreamworks.launchIntakeForm($(this));
                if (util.mediaBreakpointDown('md')) {
                    $(document).trigger('freezeBackground');
                }
            });
        }
        // event handlers needed regardless of whether the click-to-chat button is on the same page
        $(document).on('click', chatDialogCloseBtnSelector, upstreamworks.onCloseChatDialog);
        $(document).on('click', chatDialogExpandBtnSelector, upstreamworks.onExpandChatDialog);
    },

    brandAbrv: function (brand) {
        return brand.toLowerCase() === 'jcrew' ? 'JC' : 'MW'; // these values taken directly from JCrew's implementation, but with 'MW' as the final fallback.
    },

    disableClickToChatButtons: function () {
        $(clickToChatButtonSelector).attr('disabled', 'disabled');
        if (upstreamworksDebug) window.console.log('Click to chat button(s) disabled');
    },

    enableClickToChatButtons: function () {
        $(clickToChatButtonSelector).removeAttr('disabled');
        if (upstreamworksDebug) window.console.log('Click to chat button(s) enabled');
    },

    getAgentsAvailable: function (skills, callback) {
        if (upstreamworksDebug) {
            window.console.log('Agent availability not polled. Debugging is on. Returning true automatically.');
            upstreamworks.agentsAvailable = true;
            upstreamworks.enableClickToChatButtons();
            return;
        } else if (!skills) {
            if (upstreamworksDebug) window.console.log('No skills string passed to upstreamworks.getAgentsAvailable function');
            return;
        }

        var skillsObj = JSON.parse(skills);
        if (skillsObj.hasOwnProperty(skillAddressProperty)) {
            skillsObj[skillAddressProperty].forEach((o, i, a) => a[i] = upstreamworks.brandAbrv(upstreamworksBrand) + a[i]);
        } else {
            if (upstreamworksDebug) window.console.error('Invalid skills string passed to upstreamworks.getAgentsAvailable function.');
            return;
        }

        var settings = {
            'url': upstreamworksAgentPollURL,
            'method': 'POST',
            'timeout': 0,
            'headers': {
                'Content-Type': 'application/json'
            },
            'data': JSON.stringify(skillsObj)
        };

        var availableSkills = [];

        $.ajax(settings).done(function(response) {
            if (response && response.length > 0) {
                for (var skill in response) {
                    if (response[skill].hasOwnProperty(skillStatusProperty)
                        && response[skill][skillStatusProperty] === skillStatusOpen
                        && response[skill][skillCapacityProperty] >= upstreamworksAvailablityThreshold) {
                        availableSkills.push(response[skill][skillAddressProperty]);
                    }
                }
            }
            if (availableSkills.length === 0) {
                if (upstreamworksDebug) window.console.log('Agents availability too low.');
                upstreamworks.agentsAvailable = false;
                upstreamworks.disableClickToChatButtons();
            } else {
                if (upstreamworksDebug) window.console.log('Agents are available.');
                upstreamworks.agentsAvailable = true;
                upstreamworks.enableClickToChatButtons();
            }
            if (typeof callback === 'function') {
                callback(availableSkills);
            }
        });
    },

    launchExistingChat: function () {
        var formIframeURL = upstreamworksFormURL;
        formIframeURL = util.appendParamToURL(formIframeURL, 'reload', 'true');
        this.loadChatDialog(formIframeURL);
    },

    launchIntakeForm: function ($btn, error) {
        var formIframeURL = upstreamworksFormURL;
        var params = upstreamworksFormHiddenParams;
        for (var p in params) {
            if ($btn.data(params[p])) {
                formIframeURL = util.appendParamToURL(formIframeURL, params[p], $btn.data(params[p]));
            }
        }
        if (error) {
            formIframeURL = util.appendParamToURL(formIframeURL, 'error', error);
        }
        this.loadChatDialog(formIframeURL);
    },

    loadChatDialog: function (iframeURL) {
        const $chatDialog= $(chatDialogSelector);
        if (this.chatSessionActive || !$chatDialog.length) {
            if (upstreamworksDebug && this.chatSessionActive) window.console.log('Seession already active.');
            return;
        }

        if (!this.chatDialogVisible) {
            if (upstreamworksDebug) window.console.log('Showing chat dialog.');
            $chatDialog.show('fast');
            this.chatDialogVisible = true;
        }

        // load the iframe
        var $content = $chatDialog.find(chatDialogSelector + '-content');
        $content.html('');
        progress.show($content);
        $content.append($('<iframe/>').attr({
            'src': iframeURL,
            'id': chatIframeId
        }));
        // hide loading animation (progress.hide()) later on uwfChatReady event
    },

    onChatEnable: function () {
        upstreamworks.chatSessionActive = true;
        // uwfEnableButton: fired when chat is first connected successfully, and they take control of the close button
        $(chatDialogCloseBtnSelector).hide(0).addClass('white');
    },

    onChatReady: function () {
        // uwfChatReady: fired when chat scripts execute, whether on the intakee form or after a saved session is reloaded
        // This does not mean it's connected to an agent yet.
        const $content = $(chatDialogSelector).find(chatDialogSelector + '-content');
        progress.hide($content);
        $(chatDialogSelector).addClass('ready');
        sessionStorage.setItem('chatSessionActive', 'true');
    },

    onChatShown: function () {
        // uwfShowChatForActiveSession: the last window message event that is fired when a chat session actually connects
        // TODO execute functionality on uwfShowChatForActiveSession, if need
    },

    onChatRestore: function () {
        // uwfSaveChatEnvironment: fired when chat environment is restored from a saved chat session
        // TODO execute functionality on uwfSaveChatEnvironment, if needed
    },

    onChatCollapsed: function () {
        // uwfButtonClick: fired when chat wants to be collapsed
        $(chatDialogSelector).addClass('collapsed');
    },

    onChatReload: function () {
        // uwfChatFrameUrlChange: fired when an existing chat is reloaded?
        // TODO execute functionality on uwfChatFrameUrlChange, if needed
    },

    onChatEnded: function () {
        // uwfResetChatEnvironment: fired when chat is ended
        sessionStorage.removeItem('chatSessionActive');
        upstreamworks.chatSessionActive = false;
        $(chatDialogCloseBtnSelector).show(0);

        // re-enable buttons, but poll for agent availability first
        upstreamworks.getAgentsAvailable(upstreamworksSkills);
        // Note: Do not automatically close the dialog window, as user may still be asked to
        // rate their customer experience. Let users close it themselves, like JCrew does.
    },

    onCloseChatDialog: function () {
        sessionStorage.removeItem('chatSessionActive');
        upstreamworks.chatDialogVisible = false;
        $(chatDialogSelector).hide('fast', function () {
            // reset dialog box aesthetics
            $(chatDialogSelector).removeClass('ready');
            $(chatDialogSelector).removeClass('collapsed');
            $(chatDialogCloseBtnSelector).show(0).removeClass('white');
            if (upstreamworksDebug) window.console.log('Chat dialog closed.');
            $(document).trigger('unfreezeBackground');
        });
        // re-enable buttons, but poll for agent availability first
        upstreamworks.getAgentsAvailable(upstreamworksSkills);
        // TODO: if possible, manually end any active chat sessions?
    },

    onExpandChatDialog: function () {
        if (upstreamworksDebug) window.console.log('Chat dialog expanded.');
        $(chatDialogSelector).removeClass('collapsed');
    },

    onResizeWindow: function () {
        util.smartResize(function () {
            if ($(chatDialogSelector) && $(chatDialogSelector).hasClass('ready')) {
                if (util.mediaBreakpointUp('md')) {
                    $(document).trigger('unfreezeBackground');
                }
            }
        });
    }
}
module.exports = upstreamworks;
