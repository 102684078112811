'use strict';

var address = require('./address'),
    billing = require('./billing'),
    multiship = require('./multiship'),
    fluidconfigure = require('fluidconfigure/fluidconfigure'),
    shipping = require('./shipping'),
    util = require('../../util'),
    timeoutDialog = require('../../timeout-dialog'),
    progress = require('../../progress'),
    loqateAddressCheck= require('./loqateAddressCheck');

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
    address.init();
    if ($('.checkout-shipping').length > 0) {
        shipping.init();
    } else if ($('.checkout-multi-shipping').length > 0) {
        multiship.init();
    } else {
        billing.init();
    }

    loqateAddressCheck.init();
    // IPT-11329 : facebook signin while checkout
    $(document).on('click', '.oAuthIcon', function () {
        $('#OAuthProvider').val(this.id);
    });

    $('#secondary').on('click','#fakeContinueBtnSummary', function(e) {
        e.preventDefault();
        var $self = $(this);
        if ($self.is(":disabled")) {
          return;
        }
 
        $('.submit-order').find('button[type="submit"]').trigger('click');
    });
 
    $('.submit-order').off("submit.disable-buton").on("submit.disable-buton", function() {
        progress.showPlaceOrderSpinner('body');
        $("#fakeContinueBtnSummary").prop('disabled', true);
    });

    fluidconfigure.initMiniLineItems();
    fluidconfigure.initOrderReviewLineItems();
    initMiniSummary();
    initSummaryPageEmailEntry();
    initTimer();
};

function initMiniSummary() {
    $(function(){
        $(document).on('click', '.checkout-products-wrapper h5', function() {
            miniSummaryAccordion();
        }).on('keydown', '.checkout-products-wrapper h5', function(e) {
            if (e.keyCode == 13 || e.keyCode == 32) {
                e.preventDefault();
                miniSummaryAccordion();
            }
        })
    })
}

var miniSummaryAccordion = function () {
    var $checkoutProductsWrapper = $('.checkout-products-wrapper');
    var $checkoutProducts = $checkoutProductsWrapper.find('.checkout-mini-cart');
    var $checkoutProductsToggle = $checkoutProductsWrapper.find('h5');
    $checkoutProducts.slideToggle('fast', function () {
        $('#secondary.summary').toggleClass('sticky');
        $(this).parent().removeClass('rail-closed');
        if ($checkoutProducts.is(':visible')) {
            $checkoutProductsToggle.addClass('opened');
            $checkoutProductsToggle.attr('aria-expanded', true);
            $checkoutProducts.attr('aria-hidden', false);
            updateSummaryDisplayPreference(1);
            if ($(window).scrollTop() > 200 && $(window).width() > 767) {
                $('html,body').animate({
                    scrollTop: $('.checkout-products-wrapper').offset().top - 80
                }, 'fast');
            }
        } else {
            $checkoutProductsToggle.removeClass('opened');
            $checkoutProductsToggle.attr('aria-expanded', false);
            $checkoutProducts.attr('aria-hidden', true);
            updateSummaryDisplayPreference(0);
        }
    });
}

function updateSummaryDisplayPreference(value) {
    $.ajax({
        type: 'POST',
        url: Urls.updateSummaryDisplayPreference,
        data: {
            visibility: value
        }
    });
}

function initSummaryPageEmailEntry() {
    var $emailField = $('input[name$="_emailorderupdate"]');

    if ($('#fakeContinueBtnSummary').length && $emailField.length) {
        $('#fakeContinueBtnSummary').attr('disabled', 'disabled');
    }

    $emailField.blur(function() {
        $('input[name="emailAddress_hidden"]').val($(this).val());
        $('input[name="emailOptin_hidden"]').val($('input[name$="_addtoemaillist"]').val());
        $('input[name="emailPreference_hidden"]').val($('input[name$="_maillistgender"]:checked').val());

        enableDisableContinue();
        checkUserExist($emailField);
    });

    $('input[name$="_addtoemaillist"], input[name$="_maillistgender"]').change(function() {
        $('input[name="emailOptin_hidden"]').val($('input[name$="_addtoemaillist"]').val());
        $('input[name="emailPreference_hidden"]').val($('input[name$="_maillistgender"]:checked').val());
    });
}


function enableDisableContinue() {
    var $form = $('form.submit-order'),
        $emailField = $('input[name$="_emailorderupdate"]'),
        $continueBtn = $('#fakeContinueBtnSummary');

    if ($form.length && $emailField.length) {
        if (validateEmailInput($emailField)) {
            $continueBtn.removeAttr('disabled');
        } else {
            $continueBtn.attr('disabled', 'disabled');
        }
    }
}

function initTimer() {
    if (SitePreferences.SESSION_TIMEOUT_ENABLED) {
        timeoutDialog({
            continueCallback: function() {
                initTimer();
            },
            sessionExpiredCallback: function() {
                window.location.href = util.appendParamToURL(window.Urls.logout, 'timeout', true);
            }
        });
    }
}

function validateEmailInput($ele) {
    var regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test($ele.val());
}

function checkUserExist($emailField) {
    if ($emailField.val() !== '' && validateEmailInput($emailField) && !$('.sign-in-out').is(':visible')) {
        var $form = $('.checkout-order-email');
        $.ajax({
            type: 'POST',
            url: $form.data('url') + '?email=' + $emailField.val(),
        })
        .done(function (data) {
            if (data.existingCustomer) {
                $emailField.addClass('active');
                $('.checkout-shipping .login-modal').eq(0).trigger('click');
            }
        });
    }
}