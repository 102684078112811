'use strict';

var util = require('./util')

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
var init = function (options) {
    var tooltipOptions = options || {};
    tooltipOptions = Object.assign({
        items: '.tooltip',
        track: !util.isMobileSize(),
        content: function () {
            return $(this).find('.tooltip-content').html();
        },
        classes: {
            'ui-tooltip': $(this).find('.tooltip-content').data('tooltip-class') || ''
        },
        tooltipClass: $(this).find('.tooltip-content').data('tooltip-class') || ''
    }, tooltipOptions);

    $(document).tooltip(tooltipOptions);

    if (util.isMobileSize()) {
        $(document, '.save-for-later-tooltip').tooltip("option", "position", {
            my: 'center top',
            at: 'center bottom+20'
        });

        var tooltipOpen = false;
        $('body').on('click', '.tooltip', function(e) {
            if ($('.ui-tooltip').length) {
                $('.tooltip[tooltip-open=true]').tooltip('disable').attr('tooltip-open', false);
            }
            e.preventDefault();
            event.stopPropagation();
            $(this).tooltip({
                items: '.tooltip',
                content: $(this).find('.tooltip-content').html(),
                classes: {
                    'ui-tooltip': $(this).find('.tooltip-content').data('tooltip-class') || ''
                },
                tooltipClass: $(this).find('.tooltip-content').data('tooltip-class') || ''
            });
            $(this).tooltip('enable');
            $(this).tooltip('open');
            $(this).attr('tooltip-open', true);
            tooltipOpen = true;
        });
        $(document).on('click', function(event) {
            if (!$(event.target).is('.tooltip') && $(event.target).closest('.tooltip').length && $('.ui-tooltip').length) {
                $('.tooltip[tooltip-open=true]').tooltip('disable').attr('tooltip-open', false);
                tooltipOpen = false;
            }
        });
        $('.user-panel').on('scroll', function() {
            if(tooltipOpen) {
                $('.tooltip[tooltip-open=true]').tooltip('close').attr('tooltip-open', false);
                tooltipOpen = false;
            }
        });
    }

    //open close tooltip with spacebar on focus for ADA
    $('body').on('focus', '.tooltip', function() {
        if (util.isMobileSize()) {
            return;
        }
        let toolTipFocusToggle = true;

        //reinit tooltip so can call open and close on it
        $(this).tooltip({
            items: '.tooltip',
            content: $(this).find('.tooltip-content').html(),
            classes: {
                'ui-tooltip': $(this).find('.tooltip-content').data('tooltip-class') || ''
            },
            tooltipClass: $(this).find('.tooltip-content').data('tooltip-class') || ''
        });
        $(this).tooltip('enable');
        $(this).tooltip('open');

        $(this).keyup(function(e) {
            if (e.which == 32 || e.which === 13) {
                e.stopPropagation();
                if (toolTipFocusToggle) {
                    $(this).tooltip('close');
                    toolTipFocusToggle = false;
                } else {
                    $(this).tooltip('open');
                    toolTipFocusToggle = true;
                }

            }
            //prevent scroll on space bar
            $(this).keydown(function(e) {
                if (e.which === 32) {
                    e.preventDefault();
                }
            });
        });
    });

    //afterpay tooltip on click
    $(document).on('mouseenter', '.tooltip-afterpay', function() {
        $(this).tooltip(
            {
                items: '.tooltip-afterpay',
                tooltipClass: 'tooltip-afterpay-styling',
                content: function(){
                    return $(this).find('.tooltip-content').html();
                },
                close: function(event, ui) {
                    var me = this;
                    ui.tooltip.hover(
                        function () {
                            $(this).stop(true).fadeTo(400, 1);
                        },
                        function () {
                            $(this).fadeOut('400', function(){
                                $(this).remove();
                            });
                        }
                    );
                    ui.tooltip.on('remove', function(){
                        $(me).tooltip('destroy');
                    });
                }
            }
        );
        $(this).tooltip('open');
    });

    //question tooltip on hover
    $(document).on('mouseenter', '.tooltip-cvn', function() {
        $(this).siblings('.tooltip-message').addClass('active');
    });
    $(document).on('mouseleave', '.tooltip-cvn', function() {
        $(this).siblings('.tooltip-message').removeClass('active');
    });

    //applepay tooltip on click
    $(document).on('mouseenter', '.tooltip-applepay', function() {
        $(this).tooltip(
            {
                items: '.tooltip-applepay',
                tooltipClass: 'tooltip-applepay-styling',
                content: function(){
                    return $(this).find('.tooltip-content').html();
                },
                close: function(event, ui) {
                    var me = this;
                    ui.tooltip.hover(
                        function () {
                            $(this).stop(true).fadeTo(400, 1);
                        },
                        function () {
                            $(this).fadeOut('400', function(){
                                $(this).remove();
                            });
                        }
                    );
                    ui.tooltip.on('remove', function(){
                        $(me).tooltip('destroy');
                    });
                }
            }
        );
        $(this).tooltip('open');
    });

    //loyalty tooltips
    $(document).on('mouseenter', '.loyalty-tooltip .tooltip', function() {
        $(this).tooltip(
            {
                items: '.tooltip',
                tooltipClass: 'tooltip-loyalty',
                content: function(){
                    return $(this).find('.tooltip-content').html();
                }
            }
        );
        $(this).tooltip('open');
    });

    //Pre-Order tooltip
    $(document).on('mouseenter', '.tooltip.availability-message-tooltip', function() {
        $(this).tooltip(
            {
                items: '.tooltip',
                content: function(){
                    return $(this).find('.tooltip-content').html();
                },
                classes: {
                    'ui-tooltip': $(this).find('.tooltip-content').data('tooltip-class') || ''
                },
                tooltipClass: $(this).find('.tooltip-content').data('tooltip-class') || ''
            }
        );
        $(this).tooltip('open');
    });

    //Checkout shipment tooltip
    $(document).on('mouseenter', '.low-emissions-shipment-tooltip', function() {
        $(this).tooltip(
            {
                items: '.tooltip',
                content: function(){
                    return $(this).find('.tooltip-content').html();
                },
                classes: {
                    'ui-tooltip': $(this).find('.tooltip-content').data('tooltip-class') || ''
                },
                tooltipClass: $(this).find('.tooltip-content').data('tooltip-class') || '',
                track: true
            }
        );
        $(this).tooltip('open');
    });
}

/**
 * createTooltip - a new tooltip init function to replace the old tooltip.init(), with improved positioning in mobile
 * @param {jQuery} $element the DOM element to create the tooltip upon (this element will be the hover/focus trigger for the tooltip)
 * @param {object} options (optional) an object containing additional configurations
 * @param options.position a jQuery UI positioning object. see: https://api.jqueryui.com/position/
 * @param options.classes see: https://api.jqueryui.com/tooltip/#option-classes
 * @param options.content Define the tooltip content. See: https://api.jqueryui.com/tooltip/#option-content. Default if undefined: this function will look for a nested element with class name 'tooltip-content' to use.
 * @param options.disabled Init the tooltip disabled. See: https://api.jqueryui.com/tooltip/#option-disabled. default is False
 * @param options.items Define the tooltip trigger. See: https://api.jqueryui.com/tooltip/#option-classes. Default if undefined: the passed in argument, $element.
 * @param options.track Whether the tooltip should follow the mouse. See: https://api.jqueryui.com/tooltip/#option-classes. Default if undefined is True.
 * @param options.hide Hiding animation. See: https://api.jqueryui.com/tooltip/#option-classes
 * @param options.show Showing animation. See: https://api.jqueryui.com/tooltip/#option-classes
 */
var createTooltip = function ($element, options) {
    // New Tooltip Styling
    if ($element === undefined || $element.length === 0) return;
    var tooltipOptions = options || {};

    // set up tooltip content
    var tooltipContent = $element.find('.tooltip-content').html();

    // set up jQuery UI classes based on data attributes in the tooltip <a> and .tooltip-content <div>
    var tooltipClasses = {
        'ui-tooltip': $element.data('tooltip-class') || '',
        'ui-tooltip-content': $element.find('.tooltip-content').data('tooltip-class') || ''
    };

    // set up jQuery UI positioning for mobile and desktop. use options.position (if it exists) as the default desktop positioning.
    var tooltipMobilePositioning = {
        my: 'center bottom-10',
        at: 'center top',
        collision: 'flipfit fit'
    };
    var tooltipDesktopPositioning = tooltipOptions.hasOwnProperty('position') ? tooltipOptions.position : {
        my: 'right-15 center',
        at: 'left-15 center',
        collision: 'flipfit fit'
    };
    tooltipOptions.position = !util.mediaBreakpointUp('md') ? tooltipMobilePositioning : tooltipDesktopPositioning;

    // build the tooltip options
    tooltipOptions = Object.assign({
        items: $element,
        track: true,
        content: tooltipContent,
        classes: tooltipClasses
    }, tooltipOptions);

    // init tooltip
    $element.tooltip(tooltipOptions);

    // set a helpful data attribute to track whether the tooltip is open (visible) or closed
    $element.data('tooltip-open', false);
    $element.on('tooltipopen', function () {
        $(this).data('tooltip-open', true);
    });
    $element.on('tooltipclose', function () {
        $(this).data('tooltip-open', false);
    });

    //open close tooltip with spacebar or enter on focus for ADA
    $element.off('keydown.tooltipada');
    $element.on('keydown.tooltipada', function (e) {
        if (e.keyCode === 32 || e.keyCode === 13) { // 32 = spacebar, 13 = enter
            e.preventDefault();
            if ($(this).data('tooltip-open') === true) {
                $(this).tooltip('close');
            } else {
                $(this).tooltip('open');
            }
        }
    });

    //open close tooltip on click/tap
    $element.off('touchstart.tooltipADA');
    $element.on('touchstart.tooltipADA', function (e) {
        e.preventDefault();
        if ($(this).data('tooltip-open') === true) {
            $(this).tooltip('close');
        } else {
            $(this).one('tooltipopen', function () {
                // now close tooltip on any new tap. this was not working natively in mobile on tap of the tooltip element.
                setTimeout(function () {
                    $(document).one('touchstart', function () {
                        $element.tooltip('close'); // fires once, then unbinds.
                    });
                }, 0); // setting a timeout here just to force it to wait until the next touchstart event, not immediately fire on THIS touchstart event (if it exists)
            });

            $(this).tooltip('open');
        }
    });

    // reset positioning on viewport change
    util.smartResize(function () {
        $element.tooltip('option','position', !util.mediaBreakpointUp('md') ? tooltipMobilePositioning : tooltipDesktopPositioning);
    })
};

var tooltipExists = function (selectorOrjQueryElement) {
    if (typeof selectorOrjQueryElement === 'string' && $(selectorOrjQueryElement).length) {
        return !!$(selectorOrjQueryElement).data('ui-tooltip');
    } else if (typeof selectorOrjQueryElement === 'object' && selectorOrjQueryElement.length) {
        return !!selectorOrjQueryElement.data('ui-tooltip');
    }
    return false;
}

exports.init = init;
exports.create = createTooltip;
exports.exists = tooltipExists;
