'use strict';

var account = require('./account'),
    bonusProductsView = require('../bonus-products-view'),
    progress = require('../progress'),
    quickview = require('../quickview'),
    fluidconfigure = require('fluidconfigure/fluidconfigure'),
    util = require('../util'),
    cartStoreInventory = require('../storeinventory/cart'),
    wlStatus = require('../wishlist_status'),
    saveForLater = require('./saveforlater'),
    bluecore = require('../bluecore'),
    recommendationTabs = require('../recommendationTabs'),
    minicart = require('../minicart'),
    removeProductToast = require('../removeProductToast'),
    isEvents = require('../interaction-studio-events');
var maxQty = 7;

/**
 * @private
 * @function
 * @description handles layout changes and adjustments based on browser
 */
function setCartActionsPositionLoyalty() {
    if ($(window).innerWidth() >= 1024 && $('.loyalty-plus-rewards').length && $('.cart-order-totals').length && $('.cart-actions').length) {
        var loyaltyPlusRewardsHeight = $('.loyalty-plus-rewards').outerHeight();
        var cartTotalsHeight = $('.cart-order-totals').outerHeight();
        var marginTopValue = loyaltyPlusRewardsHeight - cartTotalsHeight;
        var marginTop = marginTopValue > 0 ? marginTopValue - 15 : 25;

        $('.cart-actions')[0].style.setProperty('--cartActionsMarginTop', '-' + marginTop + 'px');
    }
}

/**
 * @private
 * @function
 * @description handles layout changes and adjustments based on browser
 */
function handleViewportAdjustments() {
    var $wrapper = $('#wrapper.pt_cart')
    //Adjust spacing between main cart form & cart actions
    var $cartActions = $('.cart-actions'),
        distance = $('.cart-order-totals').length ? $('.cart-order-totals').offset().top : 0,
        height = $('.cart-order-totals').height();
    if ($(window).innerWidth() > 767 && $(window).innerWidth() < 1024) {
        $cartActions.css('top', distance + height);
    } else {
        $cartActions.css('top', 'relative');
    }
    //Move coupon code and cart-action element below order totals for mobile
    var $couponCode = $('#cart-items-form .cart-coupon-code');
    var $cartRewards = $('#cart-items-form .cart-rewards');
    if ($(window).innerWidth() < 768) {
        $couponCode.insertAfter('#cart-items-form .cart-actions');
        $cartRewards.insertAfter('#cart-items-form .cart-coupon-offers')
        $('.continue-btn').css("display", "none");
    } else {
        $couponCode.insertBefore('#cart-items-form .cart-rewards');
        $wrapper.removeAttr('style');
        $('.continue-btn').css("display", "block");
        if($('table.order-totals-table').length > 0) {
            setTimeout(() => {
                if (window.screen.width < 1200) {
                    $('[data-show-on-upscroll]').css({ "left": $('.order-totals-table')[0].getBoundingClientRect().left });
                }else {
                    $('[data-show-on-upscroll]').css({ "right": "55px" });
                }
                $('[data-show-on-upscroll]').css({ "top": $('.order-totals-table')[0].getBoundingClientRect().bottom - $('#main')[0].getBoundingClientRect().top });
                $('[data-show-on-upscroll]').css({ "margin": "25px auto 0px auto" });
            }, 500)
        }
    }

    setCartActionsPositionLoyalty();

    // sticky cart footer
    if (util.mediaBreakpointUp('md')) {
        destroyStickyFooter();
        stopStickyFooterObserver();
    } else {
        $(window).on('scroll', function() {
            if($(window).innerWidth() < 768)
                setupStickyUpscrollHandler();
        });
    }
}

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    var itemRemoved = util.getCookie('itemRemoved');
    if(itemRemoved !== "undefined" && itemRemoved !== "") {
        showNotification(JSON.parse(itemRemoved));  
        Cookies.remove('itemRemoved');
    }

    $('#cart-table').on('click', '.item-edit-details button, .not-available button', function (e) {
        e.preventDefault();
        if ($(this).hasClass('personalized')) {
            $(this).addClass('cart-personalized-active');
        }

        quickview.show({
            url: $(this).data('href'),
            source: 'cart'
        });
    })
        .on('click', '.bonus-item-actions a, .item-details .bonusproducts a', function (e) {
            e.preventDefault();
            bonusProductsView.show(this.href);
        });

    //override enter key for coupon code entry
    $('#primary input[name$="_couponCode"]').on('keydown', function (e) {

        if (e.which === 13 && $(this).val().length === 0) {
            return false;
        } else if (e.which === 13 && $(this).val().length >= 1) {
            e.preventDefault();
            if (window.s === undefined) {
                window.s = {};
            }
            $('#add-coupon').click();
        }
    });

    //to prevent multiple submissions of the form when removing a product from the cart
    var removeItemEvent = false;
    $('button[name$="deleteProduct"]').on('click', function (e) {
        var product = $(this).parent().siblings('.product-list-item').find('.name');
        saveDataForCookies(product);
        if (removeItemEvent) {
            e.preventDefault();
        } else {
            removeItemEvent = true;
        }
    });

    try {
        var iFrameDom = $('iframe.xcomponent-component-frame').contents();
        iFrameDom.find('paypal-button').hide();
    } catch (e) {
        window.console.log('paypal iframe loading problem: '+e.message);
    }

    $('body').off('click','#add-coupon').on('click','#add-coupon', function (e){
        e.preventDefault();
        var $curObj = $(this),
            $form = $curObj.closest('form'),
            $data = {};
        $('<input type="hidden" name="' + $(this).attr('name') + '" value="true" />').appendTo($form);
        $data.CouponCode = $form.find('#dwfrm_cart_couponCode').val();
        progress.show($form.parent());
        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: $form.serialize(),
            success: function(response) {
                if($(response).find('#coupon-error').text().trim() === ""){
                    $(document).trigger('addCouponSucess', [ {data: $data} ]);
                }else{
                    $data.status = $(response).find('#coupon-error').text().replaceAll('\n','').replaceAll(' ','_').replaceAll('\"','"').toLowerCase();
                    $(document).trigger('addCouponError', [ {data: $data} ]);
                }
                $form.find('.ajax-refresh').html($(response).find('.ajax-refresh').html());
                $('.cart-actions .mobile-subtotal').html($(response).find('.cart-actions .mobile-subtotal').html());
                $('.afterpay.learn-more').html($(response).find('.afterpay.learn-more').html());
                reloadGA360Data(response);
            
                initializeEvents();
                saveForLater.init();
                progress.hide();
            },
            error: function (error){
                console.log("Error: ",error)
            }
        });
    });
    
    // reduce quantity on click
    $(document).off('click', '.quantity-button.minus').on('click', '.quantity-button.minus', function() {
        var $self = $(this);
        var qty = Number($self.siblings('[name$="_quantity"]').val());
        qty -= 1;
        if(qty == 0) {
            $self.parent().parent().parent().siblings('.item-details').find('button[name$="deleteProduct"]').trigger('click');    
        } else {
            updateQty($self, qty);
        }
    });
    // increase quantity on click
    $(document).off('click', '.quantity-button.plus').on('click', '.quantity-button.plus', function() {
        var $self = $(this);
        var qty = Number($self.siblings('[name$="_quantity"]').val());
        if (qty < maxQty) {
            qty += 1;
            updateQty($self, qty);
        } else {
            return
        }
    });
    // handle input change
    $(document).off('change.type-quantity').on('change.type-quantity', '[name$="_quantity"]', function(){
        var $self = $(this);
        var qty = Number($self.val());
        if(this.value == 0) {
            $self.parent().parent().parent().siblings('.item-details').find('button[name$="deleteProduct"]').trigger('click');
        } else if(this.value > maxQty){
            this.value = maxQty;
            qty = maxQty;
            updateQty(qty, $closeBtn);
        } else {
            updateQty($self, qty);
        }
    });
    // avoid writing text
    $(document).off('input.type-quantity').on('input.type-quantity', '[name$="_quantity"]', function(){
        this.value = this.value.replace(/[^\d]/g, '');
    });
    // highlight state
    $(document).off('focus').on('focus', '[name$="_quantity"]', function() {
        $(this).parent('.quantity').addClass('input-highlight');
    });
    $(document).off('blur').on('blur', '[name$="_quantity"]', function() {
        $(this).parent('.quantity').removeClass('input-highlight');
    });

    $('select[name$="_state"]').on('change', function () {
        $(this).parent('select-style').removeClass('select-style-error');
    })

    $('.cart-row.full-out-of-stock').find('input.delivery-option').attr('disabled', 'true');

    if ($('.order-value').text() == '$0.00') {
        //$('.paypal-button-container-cart1, .dw-apple-pay-button, .paypal-button-label-venmo').remove();
    }

    setTimeout(function() {
        if ($('.apple-error').length > 0) {
            $('.dw-apple-pay-button').attr('disabled', 'disabled');
        }
    }, 2000);

    // Bluecore + Interaction Studio remove item events
    $('button[name$="deleteProduct"]').on('click', function (e) {
        var itemDetails = $(this).parents('.cart-row');
        var pid = itemDetails.find('.cart-pid').val();
        var count = itemDetails.find('.item-quantity .select-style select').val();
        var salesprice = itemDetails.find('.cart-price').attr('data-price');
        var variantData = itemDetails.find('input[name=variantData]').val() || '';
        if (variantData !== null && variantData !== undefined && variantData !== '') {
            try {
                variantData = JSON.parse(variantData);
            } catch (error) {
                variantData = {};
            }
        } else {
            variantData = {};
        }
        var sku ='';
        var styleColor ='';
        var priceLocal = '';
        var variant = '';
        if (variantData && variantData.sku) {
            sku = variantData.sku;
        }
        if (variantData && variantData.priceLocal) {
            priceLocal = variantData.priceLocal;
        }
        if (variantData && variantData.variant) {
            variant = variantData.variant;
        }
        if (variantData && variantData.styleColor) {
            styleColor = variantData.styleColor;
        }

        if (!$(this).hasClass('remove')) {
            e.preventDefault();
        } else {
            isEvents.removeCartItem(pid, count, salesprice, true, priceLocal, sku, variant, styleColor);
        }

        if ($(this).hasClass('save-for-later')) {
            isEvents.saveForLater(pid, count, salesprice, priceLocal, sku, variant, styleColor);
        }

        if (!$(this).hasClass('login-modal')) {
            bluecore.pushRemoveItem($(this).closest('tr').find('.cart-pid').val(), $(this).data('masterproductid'));
        }
    });

    $(document).off('click.add-product').on('click.add-product', '.toast-flex-undo.add-to-cart', function() {
        var $self = $(this);
        var product;
        var isGiftCard = $self.attr('data-gtmdata') && JSON.parse($self.attr('data-gtmdata')).item_name.includes('Gift Card');
        var monogrammingKey = $self.attr('data-personalized');
        var monogrammingValue = $self.attr('data-personalizedvalue');
        if(monogrammingKey) {
            product = {
                [monogrammingKey]: monogrammingValue,
            }
        }
        if (isGiftCard) {
            product = {
                dwfrm_giftcert_purchase_recipient: $self.attr('data-dwfrm_giftcert_purchase_recipient'),
                dwfrm_giftcert_purchase_from: $self.attr('data-dwfrm_giftcert_purchase_from'),
                dwfrm_giftcert_purchase_messageEGift: $self.attr('data-dwfrm_giftcert_purchase_messageegift'),
                dwfrm_giftcert_purchase_messageClassic1: $self.attr('data-dwfrm_giftcert_purchase_messageclassic1'), 
                dwfrm_giftcert_purchase_messageClassic2: $self.attr('data-dwfrm_giftcert_purchase_messageclassic2'),
                dwfrm_giftcert_purchase_sendDate: $self.attr('data-dwfrm_giftcert_purchase_senddate'),
                dwfrm_giftcert_purchase_recipientEmail: $self.attr('data-dwfrm_giftcert_purchase_recipientemail'),
                dwfrm_giftcert_purchase_confirmRecipientEmail: $self.attr('data-dwfrm_giftcert_purchase_recipientemail'),
                dwfrm_giftcert_purchase_fromEmail: $self.attr('data-dwfrm_giftcert_purchase_fromemail'),
                pid: $self.attr('data-productid'),
                quantity: $self.attr('data-quantity'),
                amount: $self.attr('data-amount'),
                cardDesign: $self.attr('data-carddesign'),
                name: $self.attr('data-productname')
            }
        } else {
            product = {
                quantity: $self.attr('data-quantity'),
                cartAction: $self.attr('data-cartAction'),
                addtocartlocation: $self.attr('data-addtocartlocation'),
                pid: $self.attr('data-productid'),
                masterPid: $self.attr('data-masterproductid'),
                upc: $self.attr('data-upc'),
                isMasterProduct: $self.attr('data-isMasterProduct'),
                variationData: $self.attr('data-variationData'),
                name: $self.attr('data-productname'),
                recipeId: $self.attr('data-recipeid'),
            };
        }
        if (monogrammingKey !== '') {
            product[monogrammingKey] = monogrammingValue;
        }
        undo(product);
    })

    fluidconfigure.initCartLineItems();

    // If the user lands on the Registration page, ensure that it functions the same as in the modal.
    // Hide/Show the Password requirements when password field is focused out/focused.
    $('#RegistrationForm').find('input[name$="_password"]')
        .on('focus', function() {
            $(this).parents('.form-row').find('.form-caption').show();
        })
        .on('focusout', function() {
            $(this).parents('.form-row').find('.form-caption').hide();
        });

    $(window).on('load', function() {
        var isapplepayElement = $('.apple-pay-container');
        if ('ApplePaySession' in window) {
            isapplepayElement.attr('aria-label',"apple pay");
            isapplepayElement.removeClass('no-rewards-on-page-load');
        }
    });

    $(function() {
        // GA v2 tagging needs to remain available during GA360 transition to prevent compatibility issues with existing 3rd party tags in GTM
        //return;
        var cartData = $('#isCartData').val();
        if (cartData !== null && cartData !== 'null' && cartData !== ''){
            var products = [];
            try {
                products = JSON.parse(cartData).products;
            } catch (e) {
                products = [];
            }
            isEvents.pushCartData(products);
        }
    });

    if($(window).innerWidth() < 768) {
        startStickyFooterObserver();
        showStickyCartFooter();
    }

    handleViewportAdjustments();

    util.smartResize(function() {
        handleViewportAdjustments();
    });
}

function showNotification(product) {
    removeProductToast.init($('#cart-table').find('tbody'), product);
}

function updateQty(ele, qty) {
    var $curObj = ele;
    var qty = qty;
    if (!isNaN(qty)) {
        var row = $curObj.closest('tr.cart-row');
        var pid = row.find('.cart-pid').val();
        var price = row.find('.cart-price').data('price');
        var variantData = row.find('input[name=variantData]').val() || '';
        var url = Urls.updateQty;
        var recipeId = row.data('recipeid');
        var uuid = row.data('uuid');
        var param = {
            pid: pid,
            Quantity: qty
        };
        param.uuid = uuid;
        param.format = 'ajax';

        var personalizedOptionValue = row.find('[name$="_isPersonalized"]');
        if (personalizedOptionValue.size() > 0) {
            param[personalizedOptionValue.first().attr('name')] = personalizedOptionValue.first().val();
        }
        if (recipeId) {
            param.recipeId = recipeId;
        }

        url = util.appendParamsToUrl(url, param);
        $.ajax({
            url : url,
            success : function (response){
                $('.ajax-refresh').html($(response).find('.ajax-refresh').html());
                $('.cart-actions .mobile-subtotal').html($(response).find('.cart-actions .mobile-subtotal').html());
                $('body #mini-cart').html($(response).find('#mini-cart').html());
                $('.afterpay.learn-more').html($(response).find('.afterpay.learn-more').html());
                initializeEvents();
                disableQtyButtons();
                saveForLater.init();
                $('.cart-row ').each(function() {
                    cartStoreInventory.updateCartRowLinks(this);
                });
                $('tr.cart-row[data-uuid="' + uuid + '"] select[name$="_quantity"]').focus();
                bluecore.pushBasketGenderObject(true);

                var $newValue = $(response).find('#ga360Data').attr('value');
                $('#ga360Data').attr('value', $newValue).val($newValue);

                var isEvents = require('../interaction-studio-events');
                var isUpdate = true;

                if (variantData !== null && variantData !== undefined && variantData !== '') {
                    try {
                        variantData = JSON.parse(variantData);
                    } catch (error) {
                        variantData = {};
                    }
                } else {
                    variantData = {};
                }
                var sku ='';
                var styleColor = '';
                var priceLocal = '';
                var variant = '';
                if (variantData && variantData.sku) {
                    sku = variantData.sku;
                }
                if (variantData && variantData.priceLocal) {
                    priceLocal = variantData.priceLocal;
                }
                if (variantData && variantData.variant) {
                    variant = variantData.variant;
                }
                if (variantData && variantData.styleColor) {
                    styleColor = variantData.styleColor;
                }

                isEvents.triggerATC(pid, qty, price, priceLocal, sku, variant, isUpdate, null, styleColor);
                if (SitePreferences.STYLITICS_FORCE_V3 && typeof StyliticsClassicWidget !== 'undefined') {
                    window.styliticsWidget.trackAddItemToCart(pid);
                }
            }
        });
    }
}

function saveDataForCookies(product) {
    util.createCookie('itemRemoved', JSON.stringify(buildProductData(product)));
}

function buildProductData(product) {
    var productData = {};
    var scrollTop = $(window).scrollTop();
    var parentProduct = product.closest('.cart-row');
    var productItem = product.parents('.product-list-item');
    var quantity = parentProduct.find('.item-quantity').contents().filter(function() {
        return this.nodeType === 3; 
      }).text().trim();
    var pid = parentProduct.find('.cart-pid').val();
    var price = parentProduct.find('.cart-price').attr('data-price');
    if(product.text().includes('Classic')){
        var prodName = product.text();
        var recipient = productItem.find('.attribute').eq(0).find('.value').text();
        var fromName = productItem.find('.attribute').eq(1).find('.value').text();
        var giftMsg = null;
        var classicMsg = productItem.find('.attribute').eq(3).find('.value');
        var msgClassic1 = classicMsg.contents().filter(function() {
            return this.nodeType === 3;
            }).first().text().trim();
        var msgClassic2 = classicMsg.contents().filter(function() {
            return this.nodeType === 3;
            }).last().text().trim();
        var sendDate = null;
        var recipientEmail = null;
        var fromEmail = productItem.find('.attribute').eq(2).find('.value').text();
        var giftImage = parentProduct.find('.item-image').find('img').attr('src');
        var giftName = null;
        var giftId = null;
        var cardDesign = {id:giftId, name:giftName, image:giftImage};
    } else if (product.text().includes('Electronic')) {
        var prodName = product.text();
        var recipient = productItem.find('.attribute').eq(0).find('.value').text();
        var fromName = productItem.find('.attribute').eq(2).find('.value').text();
        var giftMsg = productItem.find('.attribute').eq(4).find('.value').text();
        var msgClassic1 = null;
        var msgClassic2 = null;
        var sendDate = productItem.find('.attribute').eq(1).find('div').eq(1).find('.value').text();
        var recipientEmail = productItem.find('.attribute').eq(1).find('div').eq(0).find('.value').text();
        var fromEmail = productItem.find('.attribute').eq(3).find('.value').text();
        var giftImage = parentProduct.find('.item-image').find('img').attr('src');
        var giftName = productItem.find('.attribute').eq(1).find('div').eq(2).find('.value').text();
        var giftId = giftName.toLowerCase().replace(/\s+/g, '-').replace(/^-+|-+$/g, '');
        var cardDesign = {id:giftId, name:giftName, image:giftImage};
    } else {
        var name = productItem.find('.name').children().text();
        var id = parentProduct.attr('data-uuid');
        var masterpid = parentProduct.find('.remove').attr('data-masterproductid')
        var url = util.appendParamsToUrl(Urls.minicartRemoveProudct, {id: id, pid: pid, format: 'ajax'});
        var variantData = parentProduct.find('[name="variantData"]').val();
        var variation = variantData == 'null' || null ? '' : JSON.parse(variantData).variant;
        var upc = variantData == 'null' || null ? '' : JSON.parse(variantData).styleColor.replace('_','');
        var colorcode = variantData == 'null' || null ? '' : JSON.parse(variantData).styleColor.split('_')[0];
        var colorname = parentProduct.find('[data-attribute="color"] > .value').text();
        var size =  parentProduct.find('[data-attribute="size"] > .value').text();
        var pdpurl = parentProduct.find('.name').children().attr('href');
        var recipeId = parentProduct.data('recipeid') || '';
        var monogrammingProduct = parentProduct.find('[name$="_isPersonalized"]').attr('name') ?? '';
        var monogrammingProductValue = parentProduct.hasClass('personalized') ? 'Yes' : 'No';
    }
    if(product.text().includes('Gift Card')) {
        productData = {
            name: prodName,
            dwfrm_giftcert_purchase_recipient: recipient,
            dwfrm_giftcert_purchase_from: fromName,
            dwfrm_giftcert_purchase_messageEGift: giftMsg,
            dwfrm_giftcert_purchase_messageClassic1: msgClassic1, 
            dwfrm_giftcert_purchase_messageClassic2: msgClassic2,
            dwfrm_giftcert_purchase_sendDate: sendDate,
            dwfrm_giftcert_purchase_recipientEmail: recipientEmail,
            dwfrm_giftcert_purchase_confirmRecipientEmail: recipientEmail,
            dwfrm_giftcert_purchase_fromEmail: fromEmail,
            pid: pid,
            quantity: Number(quantity),
            amount: Number(price),
            cardDesign: cardDesign,
            containerIndex: parentProduct.index('.cart-row'),
            scrollPosition: scrollTop
        }
    } else {
        productData = {
            name: name,
            price: Number(price),
            id: id,
            pid: pid,
            masterpid: masterpid,
            url: url,
            variation: variantData,
            quantity: quantity,
            pdpurl: pdpurl,
            upc: upc, 
            colorcode: colorcode,
            colorname: colorname,
            size: size,
            containerIndex: parentProduct.index('.cart-row'),
            scrollPosition: scrollTop,
            recipeId: recipeId,
            isPersonalized: monogrammingProduct,
            isPersonalizedValue: monogrammingProductValue
        }
    }
    minicart.cleanObjectValues(productData);
    return productData
}

async function undo(product) {
    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: product
    })).then(function (response) {
        minicart.show(response, true);
        return response;
    });    
}

function destroyStickyFooter() {
    hideStickyCartFooter();
    $(window).off('scroll.stickyupscroll');
}

function startStickyFooterObserver () {
    // Intersection observer here - when the footer is intersecting, remove the sticky cart footer

    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    window.footerObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                // is intersecting
                destroyStickyFooter();
            } else {
                // is not intersecting
                setupStickyUpscrollHandler();
            }
        });
    });
    window.footerObserver.observe(document.querySelector('.footer-container'));
}

function stopStickyFooterObserver() {
    if (!window.footerObserver) return;
    window.footerObserver.unobserve(document.querySelector('.footer-container'));
}

function setupStickyUpscrollHandler() {
    var prevScrollpos = window.scrollY;
    var isAtBottom = false;
    $(window).on('scroll.stickyupscroll', function () {
        var currentScrollPos = window.scrollY;
        var maxScrollPos = document.documentElement.scrollHeight - window.innerHeight;
        
        if (currentScrollPos >= maxScrollPos - 1) {
            isAtBottom = true;
            hideStickyCartFooter();
        } else {
            isAtBottom = false;
        }

        if ((prevScrollpos > currentScrollPos || currentScrollPos <= 50) && !isAtBottom) {
            showStickyCartFooter();
        } else {
            hideStickyCartFooter();
        }
        prevScrollpos = currentScrollPos;
    });
}

function hideStickyCartFooter() {
    $('[data-show-on-upscroll]').css({ "position": "absolute" });
    $('[data-show-on-upscroll]').css({ "box-shadow": "unset" });
    $('.cart-footer .cart-order-totals').css({ "margin-bottom": "0"})
    $('.cart-footer .cart-order-totals').css({ "border-bottom": "unset"})
    $('.cart-actions .mobile-subtotal').css({ "display": "none"})
    $('[data-show-on-upscroll]').css({ "padding": "0 0 30px 0" });
    $('[data-show-on-upscroll]').css({ "width": "calc(100% - 50px)" });
    if (window.screen.width < 768) {
        $('[data-show-on-upscroll]').css({ "margin": "20px auto 0px" });
        $('[data-show-on-upscroll]').css({ "top": "auto" });
        $('[data-show-on-upscroll]').css({ "bottom": $('.cart-footer .cart-coupon-offers').height() + 15});
        $('[data-show-on-upscroll]').css({ "left": "25px"});
        $('[data-show-on-upscroll]').css({ "border-bottom": "1px solid #000" });
        $('.cart-footer .cart-order-totals').css({ "padding-bottom": $('[data-show-on-upscroll]').height() + 65 });
    }
}

function showStickyCartFooter() {
    $('[data-show-on-upscroll]').css({ "position": "fixed" });
    $('[data-show-on-upscroll]').css({ "left": "0" });
    $('[data-show-on-upscroll]').css({ "box-shadow": "0 -4px 4px -2px rgba(0, 0, 0, 0.25)" });
    $('[data-show-on-upscroll]').css({ "top": "auto" });
    $('[data-show-on-upscroll]').css({ "bottom": "0" });
    $('.cart-footer .cart-order-totals').css({ "padding-bottom": "0" });
    $('.cart-footer .cart-order-totals').css({ "margin-bottom": "30px"})
    $('.cart-footer .cart-order-totals').css({ "border-bottom": "1px solid #000"})
    $('.cart-actions .mobile-subtotal').css({ "display": "block"})
    $('[data-show-on-upscroll]').css({ "border-bottom": "unset" });
    $('[data-show-on-upscroll]').css({ "padding": "17px 25px" });
    $('[data-show-on-upscroll]').css({ "width": "100%" });
    $('[data-show-on-upscroll]').css({ "margin": "0" });
}

function disableQtyButtons () {
    var $product = $('.cart-row');
    if($product.length > 0) {
        $product.each(function() {
            var quantityInput = $(this).find('input[name$="_quantity"]');
            var plusButton = $(this).find('button.quantity-button.plus');
            if (quantityInput.val() == maxQty) {
                plusButton.prop('disabled', true).addClass('disabled');
            }
        })
    }   
}

function reloadGA360Data (response) {
    var $newValue = $(response).find('#ga360Data').attr('value');
    $('#ga360Data').attr('value', $newValue).val($newValue);
}

exports.reloadGA360Data = function (response) {
    reloadGA360Data(response);
}

exports.init = function () {
    initializeEvents();
    disableQtyButtons();
    if (SitePreferences.STORE_PICKUP) {
        cartStoreInventory.init();
    }
    account.initCartLogin();
    wlStatus.init();
    saveForLater.init();
    recommendationTabs.init();
};
exports.cartEvents = function () {
    initializeEvents();
    saveForLater.init();
};
exports.handleViewportAdjustments = function () {
    handleViewportAdjustments();
};
